export default [
  {
    name: 'Afghanistan',
    phone_code: '93',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Afghanistan',
      flag: 'https://restfulcountries.com/assets/images/flags/Afghanistan.png',
    },
  },
  {
    name: 'Albania',
    phone_code: '355',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Albania',
      flag: 'https://restfulcountries.com/assets/images/flags/Albania.png',
    },
  },
  {
    name: 'Algeria',
    phone_code: '213',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Algeria',
      flag: 'https://restfulcountries.com/assets/images/flags/Algeria.png',
    },
  },
  {
    name: 'Andorra',
    phone_code: '376',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Andorra',
      flag: 'https://restfulcountries.com/assets/images/flags/Andorra.png',
    },
  },
  {
    name: 'Angola',
    phone_code: '244',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Angola',
      flag: 'https://restfulcountries.com/assets/images/flags/Angola.png',
    },
  },
  {
    name: 'Antigua And Barbuda',
    phone_code: '+1-268',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Antigua%20And%20Barbuda',
      flag: 'https://restfulcountries.com/assets/images/flags/Antigua-And-Barbuda.png',
    },
  },
  {
    name: 'Argentina',
    phone_code: '54',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Argentina',
      flag: 'https://restfulcountries.com/assets/images/flags/Argentina.png',
    },
  },
  {
    name: 'Armenia',
    phone_code: '374',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Armenia',
      flag: 'https://restfulcountries.com/assets/images/flags/Armenia.png',
    },
  },
  {
    name: 'Australia',
    phone_code: '61',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Australia',
      flag: 'https://restfulcountries.com/assets/images/flags/Australia.png',
    },
  },
  {
    name: 'Austria',
    phone_code: '43',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Austria',
      flag: 'https://restfulcountries.com/assets/images/flags/Austria.png',
    },
  },
  {
    name: 'Azerbaijan',
    phone_code: '994',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Azerbaijan',
      flag: 'https://restfulcountries.com/assets/images/flags/Azerbaijan.png',
    },
  },
  {
    name: 'Bahamas',
    phone_code: '+1-242',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bahamas',
      flag: 'https://restfulcountries.com/assets/images/flags/Bahamas.png',
    },
  },
  {
    name: 'Bahrain',
    phone_code: '973',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bahrain',
      flag: 'https://restfulcountries.com/assets/images/flags/Bahrain.png',
    },
  },
  {
    name: 'Bangladesh',
    phone_code: '880',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bangladesh',
      flag: 'https://restfulcountries.com/assets/images/flags/Bangladesh.png',
    },
  },
  {
    name: 'Barbados',
    phone_code: '+1-246',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Barbados',
      flag: 'https://restfulcountries.com/assets/images/flags/Barbados.png',
    },
  },
  {
    name: 'Belarus',
    phone_code: '375',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Belarus',
      flag: 'https://restfulcountries.com/assets/images/flags/Belarus.png',
    },
  },
  {
    name: 'Belgium',
    phone_code: '32',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Belgium',
      flag: 'https://restfulcountries.com/assets/images/flags/Belgium.png',
    },
  },
  {
    name: 'Belize',
    phone_code: '501',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Belize',
      flag: 'https://restfulcountries.com/assets/images/flags/Belize.png',
    },
  },
  {
    name: 'Benin',
    phone_code: '229',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Benin',
      flag: 'https://restfulcountries.com/assets/images/flags/Benin.png',
    },
  },
  {
    name: 'Bhutan',
    phone_code: '975',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bhutan',
      flag: 'https://restfulcountries.com/assets/images/flags/Bhutan.png',
    },
  },
  {
    name: 'Bolivia',
    phone_code: '591',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bolivia',
      flag: 'https://restfulcountries.com/assets/images/flags/Bolivia.png',
    },
  },
  {
    name: 'Bosnia and Herzegovina',
    phone_code: '387',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bosnia%20and%20Herzegovina',
      flag: 'https://restfulcountries.com/assets/images/flags/Bosnia-Herzegovina.png',
    },
  },
  {
    name: 'Botswana',
    phone_code: '267',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Botswana',
      flag: 'https://restfulcountries.com/assets/images/flags/Botswana.png',
    },
  },
  {
    name: 'Brazil',
    phone_code: '55',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Brazil',
      flag: 'https://restfulcountries.com/assets/images/flags/Brazil.png',
    },
  },
  {
    name: 'Brunei',
    phone_code: '673',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Brunei',
      flag: 'https://restfulcountries.com/assets/images/flags/Brunei.png',
    },
  },
  {
    name: 'Bulgaria',
    phone_code: '359',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Bulgaria',
      flag: 'https://restfulcountries.com/assets/images/flags/Bulgaria.png',
    },
  },
  {
    name: 'Burkina Faso',
    phone_code: '226',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Burkina%20Faso',
      flag: 'https://restfulcountries.com/assets/images/flags/Burkina-Faso.png',
    },
  },
  {
    name: 'Burundi',
    phone_code: '257',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Burundi',
      flag: 'https://restfulcountries.com/assets/images/flags/Burundi.png',
    },
  },
  {
    name: 'Cambodia',
    phone_code: '855',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Cambodia',
      flag: 'https://restfulcountries.com/assets/images/flags/Cambodia.png',
    },
  },
  {
    name: 'Cameroon',
    phone_code: '237',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Cameroon',
      flag: 'https://restfulcountries.com/assets/images/flags/Cameroon.png',
    },
  },
  {
    name: 'Canada',
    phone_code: '1',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Canada',
      flag: 'https://restfulcountries.com/assets/images/flags/Canada.png',
    },
  },
  {
    name: 'Central African Republic',
    phone_code: '236',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Central%20African%20Republic',
      flag: 'https://restfulcountries.com/assets/images/flags/Central-African-Republic.png',
    },
  },
  {
    name: 'Chad',
    phone_code: '235',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Chad',
      flag: 'https://restfulcountries.com/assets/images/flags/Chad.png',
    },
  },
  {
    name: 'Chile',
    phone_code: '56',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Chile',
      flag: 'https://restfulcountries.com/assets/images/flags/Chile.png',
    },
  },
  {
    name: 'China',
    phone_code: '86',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/China',
      flag: 'https://restfulcountries.com/assets/images/flags/China.png',
    },
  },
  {
    name: 'Colombia',
    phone_code: '57',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Colombia',
      flag: 'https://restfulcountries.com/assets/images/flags/Colombia.png',
    },
  },
  {
    name: 'Comoros',
    phone_code: '269',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Comoros',
      flag: 'https://restfulcountries.com/assets/images/flags/Comoros.png',
    },
  },
  {
    name: 'Congo',
    phone_code: '242',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Congo',
      flag: 'https://restfulcountries.com/assets/images/flags/Congo.png',
    },
  },
  {
    name: 'Costa Rica',
    phone_code: '506',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Costa%20Rica',
      flag: 'https://restfulcountries.com/assets/images/flags/Costa-Rica.png',
    },
  },
  {
    name: "Cote D'Ivoire",
    phone_code: '225',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Cote%20D%27Ivoire',
      flag: 'https://restfulcountries.com/assets/images/flags/Cote-d-Ivoire.png',
    },
  },
  {
    name: 'Croatia',
    phone_code: '385',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Croatia',
      flag: 'https://restfulcountries.com/assets/images/flags/Croatia.jpg',
    },
  },
  {
    name: 'Cuba',
    phone_code: '53',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Cuba',
      flag: 'https://restfulcountries.com/assets/images/flags/Cuba.png',
    },
  },
  {
    name: 'Cyprus',
    phone_code: '357',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Cyprus',
      flag: 'https://restfulcountries.com/assets/images/flags/Cyprus.png',
    },
  },
  {
    name: 'Czech Republic',
    phone_code: '420',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Czech%20Republic',
      flag: 'https://restfulcountries.com/assets/images/flags/Czech-Republic.png',
    },
  },
  {
    name: 'Denmark',
    phone_code: '45',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Denmark',
      flag: 'https://restfulcountries.com/assets/images/flags/Denmark.png',
    },
  },
  {
    name: 'Djibouti',
    phone_code: '253',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Djibouti',
      flag: 'https://restfulcountries.com/assets/images/flags/Djibouti.png',
    },
  },
  {
    name: 'Dominica',
    phone_code: '+1-767',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Dominica',
      flag: 'https://restfulcountries.com/assets/images/flags/Dominica.png',
    },
  },
  {
    name: 'Dominican Republic',
    phone_code: '+1-809 and 1-829',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Dominican%20Republic',
      flag: 'https://restfulcountries.com/assets/images/flags/Dominican-Republic.png',
    },
  },
  {
    name: 'Ecuador',
    phone_code: '593',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Ecuador',
      flag: 'https://restfulcountries.com/assets/images/flags/Ecuador.png',
    },
  },
  {
    name: 'Egypt',
    phone_code: '20',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Egypt',
      flag: 'https://restfulcountries.com/assets/images/flags/Egypt.png',
    },
  },
  {
    name: 'El Salvador',
    phone_code: '503',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/El%20Salvador',
      flag: 'https://restfulcountries.com/assets/images/flags/El-Salvador.png',
    },
  },
  {
    name: 'Equatorial Guinea',
    phone_code: '240',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Equatorial%20Guinea',
      flag: 'https://restfulcountries.com/assets/images/flags/Equatorial-Guinea.png',
    },
  },
  {
    name: 'Eritrea',
    phone_code: '291',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Eritrea',
      flag: 'https://restfulcountries.com/assets/images/flags/Eritrea.png',
    },
  },
  {
    name: 'Estonia',
    phone_code: '372',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Estonia',
      flag: 'https://restfulcountries.com/assets/images/flags/Estonia.png',
    },
  },
  {
    name: 'Ethiopia',
    phone_code: '251',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Ethiopia',
      flag: 'https://restfulcountries.com/assets/images/flags/Ethiopia.png',
    },
  },
  {
    name: 'Fiji',
    phone_code: '679',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Fiji',
      flag: 'https://restfulcountries.com/assets/images/flags/Fiji.png',
    },
  },
  {
    name: 'Finland',
    phone_code: '358',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Finland',
      flag: 'https://restfulcountries.com/assets/images/flags/Finland.png',
    },
  },
  {
    name: 'France',
    phone_code: '33',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/France',
      flag: 'https://restfulcountries.com/assets/images/flags/France.png',
    },
  },
  {
    name: 'Gabon',
    phone_code: '241',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Gabon',
      flag: 'https://restfulcountries.com/assets/images/flags/Gabon.png',
    },
  },
  {
    name: 'Gambia',
    phone_code: '220',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Gambia',
      flag: 'https://restfulcountries.com/assets/images/flags/Gambia.png',
    },
  },
  {
    name: 'Georgia',
    phone_code: '995',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Georgia',
      flag: 'https://restfulcountries.com/assets/images/flags/Georgia.png',
    },
  },
  {
    name: 'Germany',
    phone_code: '49',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Germany',
      flag: 'https://restfulcountries.com/assets/images/flags/Germany.png',
    },
  },
  {
    name: 'Ghana',
    phone_code: '233',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Ghana',
      flag: 'https://restfulcountries.com/assets/images/flags/Ghana.png',
    },
  },
  {
    name: 'Greece',
    phone_code: '30',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Greece',
      flag: 'https://restfulcountries.com/assets/images/flags/Greece.png',
    },
  },
  {
    name: 'Grenada',
    phone_code: '+1-473',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Grenada',
      flag: 'https://restfulcountries.com/assets/images/flags/Grenada.png',
    },
  },
  {
    name: 'Guatemala',
    phone_code: '502',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Guatemala',
      flag: 'https://restfulcountries.com/assets/images/flags/Guatemala.png',
    },
  },
  {
    name: 'Guinea',
    phone_code: '224',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Guinea',
      flag: 'https://restfulcountries.com/assets/images/flags/Guinea.png',
    },
  },
  {
    name: 'Guinea-Bissau',
    phone_code: '245',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Guinea-Bissau',
      flag: 'https://restfulcountries.com/assets/images/flags/Guinea-Bissau.png',
    },
  },
  {
    name: 'Guyana',
    phone_code: '592',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Guyana',
      flag: 'https://restfulcountries.com/assets/images/flags/Guyana.png',
    },
  },
  {
    name: 'Haiti',
    phone_code: '509',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Haiti',
      flag: 'https://restfulcountries.com/assets/images/flags/Haiti.png',
    },
  },
  {
    name: 'Honduras',
    phone_code: '504',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Honduras',
      flag: 'https://restfulcountries.com/assets/images/flags/Honduras.png',
    },
  },
  {
    name: 'Hungary',
    phone_code: '36',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Hungary',
      flag: 'https://restfulcountries.com/assets/images/flags/Hungary.png',
    },
  },
  {
    name: 'Iceland',
    phone_code: '354',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Iceland',
      flag: 'https://restfulcountries.com/assets/images/flags/Iceland.png',
    },
  },
  {
    name: 'India',
    phone_code: '91',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/India',
      flag: 'https://restfulcountries.com/assets/images/flags/India.png',
    },
  },
  {
    name: 'Indonesia',
    phone_code: '62',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Indonesia',
      flag: 'https://restfulcountries.com/assets/images/flags/Indonesia.png',
    },
  },
  {
    name: 'Iran',
    phone_code: '98',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Iran',
      flag: 'https://restfulcountries.com/assets/images/flags/Iran.png',
    },
  },
  {
    name: 'Iraq',
    phone_code: '964',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Iraq',
      flag: 'https://restfulcountries.com/assets/images/flags/Iraq.png',
    },
  },
  {
    name: 'Ireland',
    phone_code: '353',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Ireland',
      flag: 'https://restfulcountries.com/assets/images/flags/Ireland.png',
    },
  },
  {
    name: 'Israel',
    phone_code: '972',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Israel',
      flag: 'https://restfulcountries.com/assets/images/flags/Israel.png',
    },
  },
  {
    name: 'Italy',
    phone_code: '39',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Italy',
      flag: 'https://restfulcountries.com/assets/images/flags/Italy.png',
    },
  },
  {
    name: 'Jamaica',
    phone_code: '+1-876',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Jamaica',
      flag: 'https://restfulcountries.com/assets/images/flags/Jamaica.png',
    },
  },
  {
    name: 'Japan',
    phone_code: '81',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Japan',
      flag: 'https://restfulcountries.com/assets/images/flags/Japan.png',
    },
  },
  {
    name: 'Jordan',
    phone_code: '962',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Jordan',
      flag: 'https://restfulcountries.com/assets/images/flags/Jordan.png',
    },
  },
  {
    name: 'Kazakhstan',
    phone_code: '7',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Kazakhstan',
      flag: 'https://restfulcountries.com/assets/images/flags/Kazakhstan.png',
    },
  },
  {
    name: 'Kenya',
    phone_code: '254',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Kenya',
      flag: 'https://restfulcountries.com/assets/images/flags/Kenya.png',
    },
  },
  {
    name: 'Kiribati',
    phone_code: '686',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Kiribati',
      flag: 'https://restfulcountries.com/assets/images/flags/Kiribati.png',
    },
  },
  {
    name: 'Kuwait',
    phone_code: '965',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Kuwait',
      flag: 'https://restfulcountries.com/assets/images/flags/Kuwait.png',
    },
  },
  {
    name: 'Kyrgyzstan',
    phone_code: '996',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Kyrgyzstan',
      flag: 'https://restfulcountries.com/assets/images/flags/Kyrgyzstan.png',
    },
  },
  {
    name: 'Laos',
    phone_code: '856',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Laos',
      flag: 'https://restfulcountries.com/assets/images/flags/Laos.png',
    },
  },
  {
    name: 'Latvia',
    phone_code: '371',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Latvia',
      flag: 'https://restfulcountries.com/assets/images/flags/Latvia.png',
    },
  },
  {
    name: 'Lebanon',
    phone_code: '961',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Lebanon',
      flag: 'https://restfulcountries.com/assets/images/flags/Lebanon.png',
    },
  },
  {
    name: 'Lesotho',
    phone_code: '266',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Lesotho',
      flag: 'https://restfulcountries.com/assets/images/flags/Lesotho.png',
    },
  },
  {
    name: 'Liberia',
    phone_code: '231',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Liberia',
      flag: 'https://restfulcountries.com/assets/images/flags/Liberia.png',
    },
  },
  {
    name: 'Libya',
    phone_code: '218',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Libya',
      flag: 'https://restfulcountries.com/assets/images/flags/Libya.png',
    },
  },
  {
    name: 'Liechtenstein',
    phone_code: '423',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Liechtenstein',
      flag: 'https://restfulcountries.com/assets/images/flags/Liechtenstein.png',
    },
  },
  {
    name: 'Lithuania',
    phone_code: '370',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Lithuania',
      flag: 'https://restfulcountries.com/assets/images/flags/Lithuania.png',
    },
  },
  {
    name: 'Luxembourg',
    phone_code: '352',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Luxembourg',
      flag: 'https://restfulcountries.com/assets/images/flags/Luxembourg.png',
    },
  },
  {
    name: 'Madagascar',
    phone_code: '261',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Madagascar',
      flag: 'https://restfulcountries.com/assets/images/flags/Madagascar.png',
    },
  },
  {
    name: 'Malawi',
    phone_code: '265',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Malawi',
      flag: 'https://restfulcountries.com/assets/images/flags/Malawi.png',
    },
  },
  {
    name: 'Malaysia',
    phone_code: '60',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Malaysia',
      flag: 'https://restfulcountries.com/assets/images/flags/Malaysia.png',
    },
  },
  {
    name: 'Maldives',
    phone_code: '960',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Maldives',
      flag: 'https://restfulcountries.com/assets/images/flags/Maldives.png',
    },
  },
  {
    name: 'Mali',
    phone_code: '223',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mali',
      flag: 'https://restfulcountries.com/assets/images/flags/Mali.png',
    },
  },
  {
    name: 'Malta',
    phone_code: '356',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Malta',
      flag: 'https://restfulcountries.com/assets/images/flags/Malta.png',
    },
  },
  {
    name: 'Marshall Islands',
    phone_code: '692',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Marshall%20Islands',
      flag: 'https://restfulcountries.com/assets/images/flags/Marshall-Islands.png',
    },
  },
  {
    name: 'Mauritania',
    phone_code: '222',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mauritania',
      flag: 'https://restfulcountries.com/assets/images/flags/Mauritania.png',
    },
  },
  {
    name: 'Mauritius',
    phone_code: '230',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mauritius',
      flag: 'https://restfulcountries.com/assets/images/flags/Mauritius.png',
    },
  },
  {
    name: 'Mexico',
    phone_code: '52',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mexico',
      flag: 'https://restfulcountries.com/assets/images/flags/Mexico.png',
    },
  },
  {
    name: 'Micronesia',
    phone_code: '691',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Micronesia',
      flag: 'https://restfulcountries.com/assets/images/flags/Micronesia.png',
    },
  },
  {
    name: 'Moldova',
    phone_code: '373',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Moldova',
      flag: 'https://restfulcountries.com/assets/images/flags/Moldova.png',
    },
  },
  {
    name: 'Monaco',
    phone_code: '377',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Monaco',
      flag: 'https://restfulcountries.com/assets/images/flags/Monaco.png',
    },
  },
  {
    name: 'Mongolia',
    phone_code: '976',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mongolia',
      flag: 'https://restfulcountries.com/assets/images/flags/Mongolia.png',
    },
  },
  {
    name: 'Montenegro',
    phone_code: '382',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Montenegro',
      flag: 'https://restfulcountries.com/assets/images/flags/Montenegro.png',
    },
  },
  {
    name: 'Morocco',
    phone_code: '212',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Morocco',
      flag: 'https://restfulcountries.com/assets/images/flags/Morocco.png',
    },
  },
  {
    name: 'Mozambique',
    phone_code: '258',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Mozambique',
      flag: 'https://restfulcountries.com/assets/images/flags/Mozambique.png',
    },
  },
  {
    name: 'Myanmar',
    phone_code: '95',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Myanmar',
      flag: 'https://restfulcountries.com/assets/images/flags/Myanmar.png',
    },
  },
  {
    name: 'Namibia',
    phone_code: '264',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Namibia',
      flag: 'https://restfulcountries.com/assets/images/flags/Namibia.png',
    },
  },
  {
    name: 'Nauru',
    phone_code: '674',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Nauru',
      flag: 'https://restfulcountries.com/assets/images/flags/Nauru.png',
    },
  },
  {
    name: 'Nepal',
    phone_code: '977',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Nepal',
      flag: 'https://restfulcountries.com/assets/images/flags/Nepal.png',
    },
  },
  {
    name: 'New Zealand',
    phone_code: '64',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/New%20Zealand',
      flag: 'https://restfulcountries.com/assets/images/flags/New-Zealand.png',
    },
  },
  {
    name: 'Nicaragua',
    phone_code: '505',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Nicaragua',
      flag: 'https://restfulcountries.com/assets/images/flags/Nicaragua.png',
    },
  },
  {
    name: 'Niger',
    phone_code: '227',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Niger',
      flag: 'https://restfulcountries.com/assets/images/flags/Niger.png',
    },
  },
  {
    name: 'Nigeria',
    phone_code: '234',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Nigeria',
      flag: 'https://restfulcountries.com/assets/images/flags/Nigeria.png',
    },
  },
  {
    name: 'Norway',
    phone_code: '47',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Norway',
      flag: 'https://restfulcountries.com/assets/images/flags/Norway.png',
    },
  },
  {
    name: 'Oman',
    phone_code: '968',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Oman',
      flag: 'https://restfulcountries.com/assets/images/flags/Oman.png',
    },
  },
  {
    name: 'Pakistan',
    phone_code: '92',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Pakistan',
      flag: 'https://restfulcountries.com/assets/images/flags/Pakistan.png',
    },
  },
  {
    name: 'Palau',
    phone_code: '680',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Palau',
      flag: 'https://restfulcountries.com/assets/images/flags/Palau.png',
    },
  },
  {
    name: 'Panama',
    phone_code: '507',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Panama',
      flag: 'https://restfulcountries.com/assets/images/flags/Panama.png',
    },
  },
  {
    name: 'Papua New Guinea',
    phone_code: '675',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Papua%20New%20Guinea',
      flag: 'https://restfulcountries.com/assets/images/flags/Papua-New-Guinea.png',
    },
  },
  {
    name: 'Paraguay',
    phone_code: '595',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Paraguay',
      flag: 'https://restfulcountries.com/assets/images/flags/Paraguay.png',
    },
  },
  {
    name: 'Peru',
    phone_code: '51',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Peru',
      flag: 'https://restfulcountries.com/assets/images/flags/Peru.png',
    },
  },
  {
    name: 'Philippines',
    phone_code: '63',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Philippines',
      flag: 'https://restfulcountries.com/assets/images/flags/Philippines.png',
    },
  },
  {
    name: 'Poland',
    phone_code: '48',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Poland',
      flag: 'https://restfulcountries.com/assets/images/flags/Poland.png',
    },
  },
  {
    name: 'Portugal',
    phone_code: '351',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Portugal',
      flag: 'https://restfulcountries.com/assets/images/flags/Portugal.png',
    },
  },
  {
    name: 'Qatar',
    phone_code: '974',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Qatar',
      flag: 'https://restfulcountries.com/assets/images/flags/Qatar.png',
    },
  },
  {
    name: 'Romania',
    phone_code: '40',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Romania',
      flag: 'https://restfulcountries.com/assets/images/flags/Romania.png',
    },
  },
  {
    name: 'Russia',
    phone_code: '7',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Russia',
      flag: 'https://restfulcountries.com/assets/images/flags/Russia.png',
    },
  },
  {
    name: 'Rwanda',
    phone_code: '250',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Rwanda',
      flag: 'https://restfulcountries.com/assets/images/flags/Rwanda.png',
    },
  },
  {
    name: 'Saint Lucia',
    phone_code: '+1-758',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Saint%20Lucia',
      flag: 'https://restfulcountries.com/assets/images/flags/Saint-Lucia.png',
    },
  },
  {
    name: 'Saint Vincent And The Grenadines',
    phone_code: '+1-784',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Saint%20Vincent%20And%20The%20Grenadines',
      flag: 'https://restfulcountries.com/assets/images/flags/Saint-Vincent-And-The-Grenadines.png',
    },
  },
  {
    name: 'Samoa',
    phone_code: '685',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Samoa',
      flag: 'https://restfulcountries.com/assets/images/flags/Samoa.png',
    },
  },
  {
    name: 'San Marino',
    phone_code: '378',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/San%20Marino',
      flag: 'https://restfulcountries.com/assets/images/flags/San-Marino.png',
    },
  },
  {
    name: 'Sao Tome and Principe',
    phone_code: '239',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Sao%20Tome%20and%20Principe',
      flag: 'https://restfulcountries.com/assets/images/flags/Sao-Tome-and-Principe.png',
    },
  },
  {
    name: 'Saudi Arabia',
    phone_code: '966',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Saudi%20Arabia',
      flag: 'https://restfulcountries.com/assets/images/flags/Saudi-Arabia.png',
    },
  },
  {
    name: 'Senegal',
    phone_code: '221',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Senegal',
      flag: 'https://restfulcountries.com/assets/images/flags/Senegal.png',
    },
  },
  {
    name: 'Serbia',
    phone_code: '381',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Serbia',
      flag: 'https://restfulcountries.com/assets/images/flags/Serbia.png',
    },
  },
  {
    name: 'Seychelles',
    phone_code: '248',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Seychelles',
      flag: 'https://restfulcountries.com/assets/images/flags/Seychelles.png',
    },
  },
  {
    name: 'Sierra Leone',
    phone_code: '232',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Sierra%20Leone',
      flag: 'https://restfulcountries.com/assets/images/flags/Sierra-Leone.png',
    },
  },
  {
    name: 'Singapore',
    phone_code: '65',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Singapore',
      flag: 'https://restfulcountries.com/assets/images/flags/Singapore.png',
    },
  },
  {
    name: 'Slovakia',
    phone_code: '421',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Slovakia',
      flag: 'https://restfulcountries.com/assets/images/flags/Slovakia.png',
    },
  },
  {
    name: 'Slovenia',
    phone_code: '386',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Slovenia',
      flag: 'https://restfulcountries.com/assets/images/flags/Slovenia.png',
    },
  },
  {
    name: 'Solomon Islands',
    phone_code: '677',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Solomon%20Islands',
      flag: 'https://restfulcountries.com/assets/images/flags/Solomon-Islands.png',
    },
  },
  {
    name: 'Somalia',
    phone_code: '252',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Somalia',
      flag: 'https://restfulcountries.com/assets/images/flags/Somalia.png',
    },
  },
  {
    name: 'South Africa',
    phone_code: '27',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/South%20Africa',
      flag: 'https://restfulcountries.com/assets/images/flags/South-Africa.png',
    },
  },
  {
    name: 'South Sudan',
    phone_code: '211',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/South%20Sudan',
      flag: 'https://restfulcountries.com/assets/images/flags/South-Sudan.png',
    },
  },
  {
    name: 'Spain',
    phone_code: '34',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Spain',
      flag: 'https://restfulcountries.com/assets/images/flags/Spain.png',
    },
  },
  {
    name: 'Sri Lanka',
    phone_code: '94',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Sri%20Lanka',
      flag: 'https://restfulcountries.com/assets/images/flags/Sri-Lanka.png',
    },
  },

  {
    name: 'Sudan',
    phone_code: '249',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Sudan',
      flag: 'https://restfulcountries.com/assets/images/flags/Sudan.png',
    },
  },
  {
    name: 'Suriname',
    phone_code: '597',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Suriname',
      flag: 'https://restfulcountries.com/assets/images/flags/Suriname.png',
    },
  },
  {
    name: 'Sweden',
    phone_code: '46',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Sweden',
      flag: 'https://restfulcountries.com/assets/images/flags/Sweden.png',
    },
  },
  {
    name: 'Switzerland',
    phone_code: '41',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Switzerland',
      flag: 'https://restfulcountries.com/assets/images/flags/Switzerland.png',
    },
  },
  {
    name: 'Syria',
    phone_code: '963',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Syria',
      flag: 'https://restfulcountries.com/assets/images/flags/Syria.png',
    },
  },
  {
    name: 'Taiwan',
    phone_code: '886',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Taiwan',
      flag: 'https://restfulcountries.com/assets/images/flags/Taiwan.png',
    },
  },
  {
    name: 'Tajikistan',
    phone_code: '992',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Tajikistan',
      flag: 'https://restfulcountries.com/assets/images/flags/Tajikistan.png',
    },
  },
  {
    name: 'Tanzania',
    phone_code: '255',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Tanzania',
      flag: 'https://restfulcountries.com/assets/images/flags/Tanzania.png',
    },
  },
  {
    name: 'Thailand',
    phone_code: '66',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Thailand',
      flag: 'https://restfulcountries.com/assets/images/flags/Thailand.png',
    },
  },
  {
    name: 'Togo',
    phone_code: '228',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Togo',
      flag: 'https://restfulcountries.com/assets/images/flags/Togo.png',
    },
  },
  {
    name: 'Tonga',
    phone_code: '676',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Tonga',
      flag: 'https://restfulcountries.com/assets/images/flags/Tonga.png',
    },
  },
  {
    name: 'Trinidad And Tobago',
    phone_code: '+1-868',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Trinidad%20And%20Tobago',
      flag: 'https://restfulcountries.com/assets/images/flags/Trinidad-and-Tobago.png',
    },
  },
  {
    name: 'Tunisia',
    phone_code: '216',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Tunisia',
      flag: 'https://restfulcountries.com/assets/images/flags/Tunisia.png',
    },
  },
  {
    name: 'Turkey',
    phone_code: '90',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Turkey',
      flag: 'https://restfulcountries.com/assets/images/flags/Turkey.png',
    },
  },
  {
    name: 'Turkmenistan',
    phone_code: '993',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Turkmenistan',
      flag: 'https://restfulcountries.com/assets/images/flags/Turkmenistan.png',
    },
  },
  {
    name: 'Tuvalu',
    phone_code: '688',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Tuvalu',
      flag: 'https://restfulcountries.com/assets/images/flags/Tuvalu.png',
    },
  },
  {
    name: 'Uganda',
    phone_code: '256',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Uganda',
      flag: 'https://restfulcountries.com/assets/images/flags/Uganda.png',
    },
  },
  {
    name: 'Ukraine',
    phone_code: '380',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Ukraine',
      flag: 'https://restfulcountries.com/assets/images/flags/Ukraine.png',
    },
  },
  {
    name: 'United Arab Emirates',
    phone_code: '971',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/United%20Arab%20Emirates',
      flag: 'https://restfulcountries.com/assets/images/flags/United-Arab-Emirates.png',
    },
  },
  {
    name: 'United Kingdom',
    phone_code: '44',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/United%20Kingdom',
      flag: 'https://restfulcountries.com/assets/images/flags/United-Kingdom.png',
    },
  },
  {
    name: 'United States',
    phone_code: '1',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/United%20States',
      flag: 'https://restfulcountries.com/assets/images/flags/United-States-of-America.png',
    },
  },
  {
    name: 'Uruguay',
    phone_code: '598',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Uruguay',
      flag: 'https://restfulcountries.com/assets/images/flags/Uruguay.png',
    },
  },
  {
    name: 'Uzbekistan',
    phone_code: '998',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Uzbekistan',
      flag: 'https://restfulcountries.com/assets/images/flags/Uzbekistan.png',
    },
  },
  {
    name: 'Vanuatu',
    phone_code: '678',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Vanuatu',
      flag: 'https://restfulcountries.com/assets/images/flags/Vanuatu.png',
    },
  },
  {
    name: 'Venezuela',
    phone_code: '58',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Venezuela',
      flag: 'https://restfulcountries.com/assets/images/flags/Venezuela.png',
    },
  },
  {
    name: 'Vietnam',
    phone_code: '84',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Vietnam',
      flag: 'https://restfulcountries.com/assets/images/flags/Vietnam.png',
    },
  },
  {
    name: 'Yemen',
    phone_code: '967',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Yemen',
      flag: 'https://restfulcountries.com/assets/images/flags/Yemen.png',
    },
  },
  {
    name: 'Zambia',
    phone_code: '260',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Zambia',
      flag: 'https://restfulcountries.com/assets/images/flags/Zambia.png',
    },
  },
  {
    name: 'Zimbabwe',
    phone_code: '263',
    href: {
      self: 'https://restfulcountries.com/api/v1/countries/Zimbabwe',
      flag: 'https://restfulcountries.com/assets/images/flags/Zimbabwe.png',
    },
  },
]
